import constants from '@/app/constants'
import { isTouch } from '@/lib/util/support'

export const MAP_DATA_SRC = `${constants.PATH_DATA}/map.bin`
export const MAP_SIZE = 1024
export const MAP_HALF_SIZE = MAP_SIZE * 0.5
export const MAP_MIN_ZOOM = isTouch ? 4 : 7
export const MAP_MAX_ZOOM = 20
export const MAP_MIN_PARTICLE_SIZE = isTouch ? 12 : 18
export const MAP_MAX_PARTICLE_SIZE = 55
export const MAP_ZOOM_INTENSITY = isTouch ? 0.25 : 0.1
export const MAP_PAN_INTENSITY = isTouch ? 0.25 : 0.1
