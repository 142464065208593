import { memo } from 'react'
import { useWorldMapContext } from '../WorldMap'
import * as styles from './WorldMapContent.module.scss'
import TrackedElement from '@/providers/TrackedElements/TrackedElement'
import TrackedElementContainer from '@/providers/TrackedElements/TrackedElementContainer'

/**
 * The `WorldMapContent`
 * @returns {React.ReactElement} the element
 */
function WorldMapContent () {
  const { containerRef, sceneRef } = useWorldMapContext()

  return (
    <TrackedElementContainer ref={containerRef} className={styles.container}>
      <div ref={sceneRef} className={styles.scene} />
      <TrackedElement className={styles.minimap} trackingId="minimap" />
    </TrackedElementContainer>
  )
}

export default memo(WorldMapContent)
