import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useMemo, useRef } from 'react'
import useComponentWillMount from '@/lib/react/hooks/useComponentWillMount'
// import debounce from '@/lib/util/debounce'
import throttle from '@/lib/util/throttle'
import calculateDelay from './util/calculateDelay'
import sortByPosition from './util/sortByPosition'

// Create the context
const IntersectionContext = createContext({
  observer: null
})

// Export the context as a hook for quick usage
export const useIntersectionContext = () => {
  const context = useContext(IntersectionContext)

  return context
}

/**
 * The Intersection `Context`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Context (props) {
  const { children = null, options = null } = props
  const observer = useRef()
  const onRevealBatchDebouncedRef = useRef()

  useComponentWillMount(() => {
    const intersectionEntries = new Map()

    const onRevealBatch = () => {
      if (!intersectionEntries.size) {
        return
      }

      const sortedEntries = Array.from(intersectionEntries.values()).sort(
        sortByPosition
      )
      const len = sortedEntries.length

      sortedEntries.forEach((item, index) => {
        const delay = calculateDelay(index, len)

        item.target.style.setProperty(
          '--intersection-animation-delay',
          delay + 's'
        )

        item.target.handleIntersect(
          item.entry.isIntersecting,
          item.entry,
          delay
        )
      })

      intersectionEntries.clear()
    }

    const onRevealBatchDebounced = throttle(onRevealBatch, 20)

    const handleIntersect = entries => {
      const { scrollX, scrollY } = window

      entries.forEach(entry => {
        if (entry.target.handleIntersect) {
          if (entry.isIntersecting) {
            if (entry.target.useBatch) {
              const { boundingClientRect, target } = entry

              intersectionEntries.set(entry.target, {
                target,
                x:
                  boundingClientRect.left +
                  boundingClientRect.width * 0.5 +
                  scrollX,
                y:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.5 +
                  scrollY,
                entry
              })
            } else {
              entry.target.handleIntersect(entry.isIntersecting, entry)
            }
          } else {
            intersectionEntries.delete(entry.target)
            entry.target.handleIntersect(entry.isIntersecting, entry)
          }
        }
      })

      onRevealBatchDebounced()
    }

    observer.current = new IntersectionObserver(handleIntersect, options)
    onRevealBatchDebouncedRef.current = onRevealBatchDebounced
  }, [])

  useEffect(
    () => () => {
      if (observer.current) {
        observer.current.disconnect()
        onRevealBatchDebouncedRef.current.cancel()
      }
    },
    []
  )

  /**
   * Memo: The context object
   */
  const ctx = useMemo(
    () => ({
      observer: observer.current
    }),
    []
  )

  return (
    <IntersectionContext.Provider value={ctx}>
      {children}
    </IntersectionContext.Provider>
  )
}

/** @type {object} */
Context.propTypes = {
  children: PropTypes.node,
  options: PropTypes.shape()
}

export default Context
