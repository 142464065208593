import { memo } from 'react'
import SVGLogo from '@/resources/svg/logo/standard.svg'
import * as styles from './Header.module.scss'

/**
 * The `Header`
 * @returns {React.ReactElement} the element
 */
function Header () {
  return (
    <>
      <header className={styles.container}>
        <div className={styles.bar}>
          <div className={styles.logo}>
            <SVGLogo className={styles.full} />
          </div>
        </div>
      </header>
    </>
  )
}

export default memo(Header)
