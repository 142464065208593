import { useTexture } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import {
  AdditiveBlending,
  BufferAttribute,
  BufferGeometry,
  ClampToEdgeWrapping,
  FloatType,
  NearestFilter,
  PlaneGeometry,
  RGBAFormat,
  Vector2,
  WebGLRenderTarget
} from 'three'
import PointerPainter from '@/components/PointerPainter/PointerPainter'
import useCanvasImage from '@/lib/react/Img/useCanvasImage'
import { useWorldMapContext } from '../WorldMap'
import useWorldMapControls from '../hooks/useWorldMapControls'
import Minimap from './components/Minimap/Minimap'
import { ParticleMaterial } from './components/ParticleMaterial/ParticleMaterial'
import { TerrainMaterial } from './components/TerrainMaterial/TerrainMaterial'

/**
 * The `WorldMapCanvas`
 * @returns {React.ReactElement} the element
 */
function WorldMapCanvas () {
  const topographySrc = useCanvasImage({
    src: 'img/map/topography.webp'
  })
  const textureTopography = useTexture(topographySrc)
  const [mapSize, setMapSize] = useState(new Vector2(1024, 1024))
  const dpr = useThree(state => state.viewport.dpr)
  const { size, viewport } = useThree()
  const containerRef = useRef()
  const positionRef = useRef()
  const painterRef = useRef()
  const pointsRef = useRef()
  const paintFBO = useRef(
    new WebGLRenderTarget(1, 1, {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat,
      type: FloatType,
      wrapS: ClampToEdgeWrapping,
      wrapT: ClampToEdgeWrapping
    })
  )
  const { mapData } = useWorldMapContext()
  const {
    isDragging,
    isHover,
    state
  } = useWorldMapControls(containerRef, positionRef, pointsRef)

  // Set up the point cloud
  useEffect(() => {
    if (!pointsRef.current || !mapData) {
      return
    }

    const { height, positions, uvs, width } = mapData

    setMapSize(vec2 => vec2.set(width, height))

    // Create BufferGeometry and set position and UV attributes
    const geometry = new BufferGeometry()
    const planeGeometry = new PlaneGeometry(
      width,
      height,
      width * 0.5,
      height * 0.5
    )

    geometry.setAttribute('position', new BufferAttribute(positions, 3))
    geometry.setAttribute('uv', new BufferAttribute(uvs, 2)) // Add UVs
    pointsRef.current.geometry = geometry
    painterRef.current.geometry = planeGeometry
  }, [mapData])

  const viewportScale = useMemo(
    () => viewport.width / size.width,
    [viewport, size]
  )

  return (
    <group>
      <group scale={viewportScale}>
        <group ref={containerRef}>
          <group ref={positionRef}>
            <PointerPainter
              ref={painterRef}
              brushRadius={15}
              fadeSpeed={0.9}
              size={mapSize}
              fbo={paintFBO}
              isDragging={isDragging}
              isHover={isHover}
              pointer={state.pointer}
            >
              <bufferGeometry />
              <terrainMaterial
                key={TerrainMaterial.key}
                displacementMap={textureTopography}
                displacementScale={5}
                size={mapSize}
                paintMap={paintFBO.current.texture}
                visible={false}
                wireframe={false}
              />
            </PointerPainter>

            <points ref={pointsRef} renderOrder={0}>
              <bufferGeometry />
              <particleMaterial
                key={ParticleMaterial.key}
                transparent={true}
                pixelRatio={dpr}
                depthWrite={false}
                size={mapSize}
                blending={AdditiveBlending}
                colorLight={'#ffebd9'}
                colorShadow={'#e57715'}
                displacementMap={textureTopography}
                displacementScale={5}
                paintMap={paintFBO.current.texture}
                paintStrength={2}
                visible={true}
              />
            </points>
          </group>
        </group>
      </group>

      <Minimap />
    </group>
  )
}

export default memo(WorldMapCanvas)
