/**
 * Clamp
 * @param {number} value - the value
 * @param {number} min - the minimum range
 * @param {number} max - the maximum range
 * @returns {number} the clamped value
 */
export const clamp = (value, min, max) =>
  value < min ? min : value > max ? (max < min ? min : max) : value

/**
 * Normalized clamp
 * @param {number} value - the value
 * @param {number} min - the minimum range
 * @param {number} max - the maximum range
 * @returns {number} the normalized clamped value
 */
export const normalizedClamp = (value, min, max) => {
  const size = Math.max(0, value - min)

  return clamp(size / (max - min), 0, 1)
}

export default clamp
