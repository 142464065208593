import PropTypes from 'prop-types'
import { forwardRef, useLayoutEffect, useRef } from 'react'
import onResize from '@/lib/onResize'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import * as styles from './TrackedElement.module.scss'
import { useTrackedElementsContext } from './TrackedElements'

const TrackedElement = forwardRef((props, forwardedRef) => {
  const { Tag = 'div', children, trackingId, ...rest } = props
  const ref = useFallbackRef(forwardedRef)
  const { trackElement, untrackElement } = useTrackedElementsContext()
  const lastCoordinates = useRef({ x: 0, y: 0, width: 0, height: 0 })

  useLayoutEffect(() => {
    const el = ref.current

    const container = el.closest(`.${styles.trackedElementContainer}`) || el.parentNode

    if (!container) {
      // No page found
      console.warn('No page found for tracked element')

      return
    }

    let frame

    const refresh = () => {
      const rectElement = el.getBoundingClientRect()
      const rectPage = container.getBoundingClientRect()

      const x = Math.round(rectElement.left - rectPage.left)
      const y = Math.round(rectElement.top - rectPage.top)
      const width = Math.round(rectElement.width)
      const height = Math.round(rectElement.height)

      const l = lastCoordinates.current

      if (l.x !== x || l.y !== y || l.width !== width || l.height !== height) {
        trackElement(trackingId, { x, y, width, height })
      }

      l.x = x
      l.y = y
      l.width = width
      l.height = height
    }

    const handleResize = () => {
      cancelAnimationFrame(frame)

      frame = requestAnimationFrame(() => {
        refresh()
      })
    }

    // const handleIntersection = ([entry]) => {
    // trackElement(trackingId, { isIntersecting: entry.isIntersecting })
    // }

    const observer = new ResizeObserver(handleResize)
    // const intersectionObserver = new IntersectionObserver(handleIntersection)

    observer.observe(container)
    observer.observe(el)
    // intersectionObserver.observe(el)

    onResize.on(refresh, 0)
    refresh()

    return () => {
      cancelAnimationFrame(frame)
      observer.disconnect()
      // intersectionObserver.disconnect()
      onResize.off(refresh, 0)
      untrackElement(trackingId)
    }
  }, [trackingId])

  return (
    <Tag ref={ref} {...rest}>
      {children}
    </Tag>
  )
})

TrackedElement.displayName = 'TrackedElement'

TrackedElement.propTypes = {
  Tag: PropTypes.elementType,
  children: PropTypes.node,
  trackingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

export default TrackedElement
