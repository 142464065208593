import { shaderMaterial } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import { Color, Vector2 } from 'three'
import fragmentShader from './particle.frag'
import vertexShader from './particle.vert'

const ParticleMaterial = shaderMaterial(
  {
    time: 0,
    pixelRatio: 1,
    size: new Vector2(),
    minParticleSize: 5.0,
    maxParticleSize: 15.0,
    color: new Color(),
    colorLight: new Color(),
    colorShadow: new Color(),
    displacementMap: null,
    displacementScale: 5,
    pointerPosition: new Vector2(),
    paintMap: null,
    paintStrength: 0,
    scanlineFrequency: 5,
    scanlineSpeed: 1,
    scanlineSoftness: 0.5
  },
  vertexShader,
  fragmentShader
)

extend({ ParticleMaterial })

export { ParticleMaterial }
