import { shaderMaterial } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import { Color, Vector2 } from 'three'
import fragmentShader from './terrain.frag'
import vertexShader from './terrain.vert'

const TerrainMaterial = shaderMaterial(
  {
    paintMap: null,
    paintStrength: 0,
    displacementMap: null,
    displacementScale: 5,
    size: new Vector2(),
    pointer: new Vector2(),
    color: new Color()
  },
  vertexShader,
  fragmentShader
)

extend({ TerrainMaterial })

export { TerrainMaterial }
