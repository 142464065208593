import * as styles from './App.module.scss'
import Header from './components/interface/Header/Header'
import CanvasContainer from './gl/CanvasContainer/CanvasContainer'
import Home from './pages/Home/Home'

/**
 *
 */
export default function App () {
  return (
    <div className={styles.container}>
      <CanvasContainer>
        <Home />
        <Header />
      </CanvasContainer>
    </div>
  )
}
