import { useFrame } from '@react-three/fiber'
import gsap from 'gsap'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Euler, Vector3 } from 'three'
import OnPointer from '@/lib/OnPointer'
import clamp from '@/lib/util/clamp'
import { lerp } from '@/lib/util/interpolation'
import { isTouch } from '@/lib/util/support'
import { useWorldMapContext } from '../WorldMap'
import {
  MAP_HALF_SIZE,
  MAP_MAX_PARTICLE_SIZE,
  MAP_MAX_ZOOM,
  MAP_MIN_PARTICLE_SIZE,
  MAP_MIN_ZOOM,
  MAP_PAN_INTENSITY,
  MAP_ZOOM_INTENSITY
} from '../config'

/**
 * The `useWorldMapControls` hook
 * @param {object} containerRef - the container ref
 * @param {object} positionRef - the position ref
 * @param {object} pointsRef - the points ref
 * @returns {object} the context object
 */
export default function useWorldMapControls (
  containerRef,
  positionRef,
  pointsRef
) {
  const { mapState, onFrame, sceneRef } = useWorldMapContext()
  const [isDragging, setIsDragging] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const state = useMemo(
    () => ({
      pointer: { x: 0, y: 0 },
      drag: {
        start: { x: 0, y: 0 },
        offset: { x: 0, y: 0 },
        vector: new Vector3()
      },
      pinch: {
        start: { x: 0, y: 0 }
      },
      dragging: false,
      zoom: {
        target: 0.5
      },
      rotation: {
        target: new Euler(-1.2, 0.0, -0.4),
        current: new Euler(-1.2, 0.0, -0.4)
      },
      position: {
        start: new Vector3(),
        target: new Vector3()
      }
    }),
    []
  )

  useFrame((frameState, delta) => {
    // state.rotation.z += 0.001
    mapState.zoom.value +=
      (state.zoom.target - mapState.zoom.value) * MAP_ZOOM_INTENSITY

    mapState.zoom.value = clamp(mapState.zoom.value, 0, 1)

    const zoom = lerp(MAP_MIN_ZOOM, MAP_MAX_ZOOM, mapState.zoom.value)

    const particleSize = lerp(
      MAP_MIN_PARTICLE_SIZE,
      MAP_MAX_PARTICLE_SIZE,
      mapState.zoom.value
    )

    if (mapState.isDragging) {
      const offsetX = state.drag.offset.x / zoom
      const offsetY = state.drag.offset.y / zoom

      // Create a vector for the drag offset
      state.drag.vector.set(-offsetX, offsetY, 0) // Note: signs for screen-to-world alignment

      // Apply perspective adjustment for tilt (rotX)
      const { x: rotX, z: rotZ } = state.rotation.current

      // Adjust for tilt perspective (rotX)
      const perspectiveScale = 1 / Math.cos(rotX) // Larger tilt = more stretch

      state.drag.vector.y *= perspectiveScale
      // state.drag.vector.x *= -1 / Math.sin(rotX)

      // Apply z-rotation (twist)
      const cosZ = -Math.cos(rotZ)
      const sinZ = Math.sin(rotZ)

      const xRotated = cosZ * state.drag.vector.x - sinZ * state.drag.vector.y
      const yRotated = sinZ * state.drag.vector.x + cosZ * state.drag.vector.y

      state.drag.vector.x = xRotated
      state.drag.vector.y = yRotated

      // Apply the adjusted drag vector to target position
      state.position.target.x = state.position.start.x - state.drag.vector.x
      state.position.target.y = state.position.start.y - state.drag.vector.y

      // Copy target to current position
    }

    mapState.position.value.x +=
      (state.position.target.x - mapState.position.value.x) * MAP_PAN_INTENSITY

    mapState.position.value.y +=
      (state.position.target.y - mapState.position.value.y) * MAP_PAN_INTENSITY

    mapState.position.value.x = clamp(
      mapState.position.value.x,
      -MAP_HALF_SIZE,
      MAP_HALF_SIZE
    )

    mapState.position.value.y = clamp(
      mapState.position.value.y,
      -MAP_HALF_SIZE,
      MAP_HALF_SIZE
    )

    positionRef.current.position.copy(mapState.position.value)
    containerRef.current.rotation.copy(state.rotation.current)
    containerRef.current.scale.setScalar(zoom)

    pointsRef.current.material.uniforms.maxParticleSize.value = particleSize
    pointsRef.current.material.uniforms.time.value += delta

    onFrame()
  })

  useEffect(() => {
    if (!sceneRef.current) {
      return
    }

    const onPointer = new OnPointer(sceneRef.current)

    state.pointer = onPointer

    const handleEnter = () => {
      if (isTouch) {
        return
      }

      setIsHover(true)
    }

    const handleLeave = () => {
      if (isTouch) {
        return
      }

      setIsHover(false)
    }

    const handlePress = () => {
      console.log('PRESS')
      state.drag.start.x = onPointer.state.x
      state.drag.start.y = onPointer.state.y
      state.drag.offset.x = 0
      state.drag.offset.y = 0
      state.position.start.copy(mapState.position.value)
      onPointer.setCursorType('grabbing')
      mapState.isDragging = true
      setIsDragging(true)
    }

    const handleRelease = () => {
      console.log('RELEASE')
      mapState.isDragging = false
      setIsDragging(false)
      onPointer.setCursorType('grab')
    }

    const handleMove = () => {
      if (mapState.isDragging) {
        const offsetX = state.drag.start.x - onPointer.state.x
        const offsetY = state.drag.start.y - onPointer.state.y

        state.drag.offset.x = offsetX
        state.drag.offset.y = offsetY
      }
    }

    const handleWheel = (a, b) => {
      state.zoom.target -= b * 0.035
      state.zoom.target = clamp(state.zoom.target, 0, 1)
    }

    const handlePinch = scale => {
      setIsDragging(false)
      state.zoom.target += scale * 0.5
      state.zoom.target = clamp(state.zoom.target, 0, 1)
    }

    const handleDoubleTap = () => {
      if (
        onPointer.state.mapX !== undefined &&
        onPointer.state.mapX > 0 &&
        onPointer.state.mapY > 0
      ) {
        gsap.to(state.position.target, {
          x: -(onPointer.state.mapX - 0.5) * 1024,
          y: -(onPointer.state.mapY - 0.5) * 1024,
          duration: 0.3,
          ease: 'expo.out'
        })
      }

      gsap.to(state.zoom, {
        target: state.zoom.target > 0.8 ? 0 : 1,
        duration: 0.3
      })
    }

    onPointer.onEnter(handleEnter)
    onPointer.onLeave(handleLeave)
    onPointer.onMove(handleMove)
    onPointer.onWheel(handleWheel)
    onPointer.onPress(handlePress)
    onPointer.onRelease(handleRelease)
    onPointer.onPinchGesture(handlePinch)
    onPointer.onDoubleTap(handleDoubleTap)

    return () => {
      onPointer.offEnter(handleEnter)
      onPointer.offLeave(handleLeave)
      onPointer.offMove(handleMove)
      onPointer.offWheel(handleWheel)
      onPointer.offPress(handlePress)
      onPointer.offRelease(handleRelease)
      onPointer.offPinchGesture(handlePinch)
      onPointer.offDoubleTap(handleDoubleTap)
      onPointer.destroy()
    }
  }, [sceneRef.current])

  const ctx = useMemo(
    () => ({
      state,
      isDragging,
      isHover
    }),
    [state, isDragging, isHover]
  )

  return ctx
}
