import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

export const TrackedElementsContext = createContext({})
export const useTrackedElementsContext = () => {
  const context = useContext(TrackedElementsContext)

  return context
}

/**
 * The `useTrackedElements` hook
 * @returns {object} the context object
 */
export default function useTrackedElements () {
  const [trackedElements, setTrackedElements] = useState(new Map())

  const trackElement = useCallback((trackingId, position) => {
    setTrackedElements(lastElements => {
      const newElements = new Map([...lastElements])
      const last = newElements.get(trackingId)

      newElements.set(trackingId, {
        ...(last || {}),
        ...position
      })

      return newElements
    })
  }, [])

  const untrackElement = useCallback(trackingId => {
    setTrackedElements(lastElements => {
      const newElements = new Map([...lastElements])

      newElements.delete(trackingId)

      return newElements
    })
  }, [])

  const ctx = useMemo(
    () => ({
      trackElement,
      untrackElement,
      trackedElements
    }),
    [trackElement, untrackElement, trackedElements]
  )

  return ctx
}
