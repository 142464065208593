import { memo } from 'react'
import WorldMap from '@/components/WorldMap/WorldMap'
import { useCanvasContainer } from '@/gl/CanvasContainer/CanvasContainer'
import Lights from '@/gl/Lights/Lights'

/**
 *
 */
function Home () {
  const { canvasTunnel } = useCanvasContainer()

  return (
    <canvasTunnel.In>
      <WorldMap />
      <Lights />
    </canvasTunnel.In>
  )
}

export default memo(Home)
