import { useRef } from 'react'

/**
 * The `Lights`
 * @returns {React.ReactElement} the element
 */
export default function Lights () {
  const lightRef = useRef()

  return (
    <>
      <ambientLight color="#fff" intensity={0.5} />
      <directionalLight
        ref={lightRef}
        color="#f6f1e4"
        position={[0, 10, 14]}
        intensity={4}
      />
    </>
  )
}
