import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import * as styles from './TrackedElement.module.scss'

const TrackedElementContainer = forwardRef((props, forwardedRef) => {
  const { Tag = 'div', children, className, ...rest } = props
  const ref = useFallbackRef(forwardedRef)
  const classNameOutput = useMemo(
    () => classNames(className, styles.trackedElementContainer),
    [className]
  )

  return (
    <Tag ref={ref} className={classNameOutput} {...rest}>
      {children}
    </Tag>
  )
})

TrackedElementContainer.displayName = 'TrackedElementContainer'

TrackedElementContainer.propTypes = {
  Tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node
}

export default TrackedElementContainer
