/**
 * `toDMS`
 * @param {number} deg - the input
 * @returns {object} the parsed DMS
 */
function toDMS (deg) {
  const absolute = Math.abs(deg)
  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(1)

  return { degrees, minutes, seconds }
}

/**
 * Converts decimal latitude and longitude into DMS (degrees, minutes, seconds) format.
 * @param {number} latitude - The latitude in decimal degrees.
 * @param {number} longitude - The longitude in decimal degrees.
 * @returns {string} The formatted DMS string (e.g., "43°49'12.0\"S 169°53'24.0\"E").
 */
export function formatLatLngToDMS (latitude, longitude) {
  // Helper function to convert decimal degrees to DMS
  const latDMS = toDMS(latitude)
  const latDirection = latitude >= 0 ? 'N' : 'S'

  const lonDMS = toDMS(longitude)
  const lonDirection = longitude >= 0 ? 'E' : 'W'

  return {
    lat: `${latDMS.degrees}°${latDMS.minutes}'${latDMS.seconds}"${latDirection}`,
    lng: `${lonDMS.degrees}°${lonDMS.minutes}'${lonDMS.seconds}"${lonDirection}`
  }
}

/**
 * Converts a position in Web Mercator (Google projection) coordinates to latitude and longitude.
 * Assumes the image is centered on the map's origin (0, 0 in lat/lng).
 * @param {number} normalizedX - the normalized x (0-1)
 * @param {number} normalizedY - the normalized y (0-1)
 * @returns {{ lat: number, lng: number }} Latitude and longitude corresponding to the position.
 */
export function getLatLngFromPosition (normalizedX, normalizedY) {
  // Convert normalized coordinates to Web Mercator coordinates
  const mercatorX = (normalizedX - 0.5) * 2 * 20037508.34 // Scale X to Web Mercator meters
  const mercatorY = (0.5 - normalizedY) * 2 * 20037508.34 // Scale Y and flip vertically

  // Convert Web Mercator meters to latitude and longitude
  const lng = (mercatorX / 20037508.34) * 180 // Longitude
  const latRad = Math.atan(Math.sinh(Math.PI * (mercatorY / 20037508.34))) // Latitude in radians
  const lat = (latRad * 180) / Math.PI // Convert latitude to degrees

  return { lat, lng }
}

/**
 * Converts latitude and longitude into normalized Web Mercator coordinates (scaled by a factor).
 * @param {number} lat - The latitude in decimal degrees (default is 0).
 * @param {number} lng - The longitude in decimal degrees (default is 0).
 * @param {number} scalar - A multiplier for the resulting coordinates (default is 1).
 * @returns {{ x: number, y: number }} An object containing the scaled normalized x and y coordinates.
 */
export function getPositionFromLatLng (lat = 0, lng = 0, scalar = 1) {
  // Convert longitude to Web Mercator X coordinate
  const mercatorX = (lng / 180) * 20037508.34

  // Convert latitude to Web Mercator Y coordinate
  const latRad = (lat * Math.PI) / 180 // Convert latitude to radians
  const mercatorY =
    (Math.log(Math.tan(latRad) + 1 / Math.cos(latRad)) * 20037508.34) / Math.PI

  // Normalize X and Y to 0-1 range
  const normalizedX = mercatorX / (2 * 20037508.34) + 0.5
  const normalizedY = 0.5 - mercatorY / (2 * 20037508.34)

  return { x: normalizedX * scalar, y: normalizedY * scalar }
}
