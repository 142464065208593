import { ScreenQuad } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { useMemo } from 'react'
import { BackgroundMaterial } from './BackgroundMaterial/BackgroundMaterial'

/**
 * The `BackgroundScene`
 * @returns {React.ReactElement} the element
 */
export default function BackgroundScene () {
  const { gl, size } = useThree()
  const resolution = useMemo(
    () => [size.width * gl.getPixelRatio(), size.height * gl.getPixelRatio()],
    [size.width, size.height, gl]
  )

  return (
    <ScreenQuad scale={100} depthWrite={false} position={[0, 0, -10]}>
      <backgroundMaterial
        key={BackgroundMaterial.key}
        depthWrite={false}
        colorStart={'#7c7f7e'}
        colorEnd={'#020402'}
        resolution={resolution}
      />
    </ScreenQuad>
  )
}
