import { createContext, memo, useContext } from 'react'
import WorldMapCanvas from './WorldMapCanvas/WorldMapCanvas'
import WorldMapContent from './WorldMapContent/WorldMapContent'
import useWorldMap from './hooks/useWorldMap'
import { useCanvasContainer } from '@/gl/CanvasContainer/CanvasContainer'
import useTrackedElements, { TrackedElementsContext } from '@/providers/TrackedElements/TrackedElements'

export const WorldMapContext = createContext({})
export const useWorldMapContext = () => {
  const context = useContext(WorldMapContext)

  return context
}

/**
 * The `WorldMap`
 * @returns {React.ReactElement} the element
 */
function WorldMap () {
  const { contentTunnel } = useCanvasContainer()
  const ctx = useWorldMap()
  const trackedElementsCtx = useTrackedElements()

  return (
    <>
      <TrackedElementsContext.Provider value={trackedElementsCtx}>
        <WorldMapContext.Provider value={ctx}>
          <WorldMapCanvas />
        </WorldMapContext.Provider>
      </TrackedElementsContext.Provider>
      <contentTunnel.In>
        <TrackedElementsContext.Provider value={trackedElementsCtx}>
        <WorldMapContext.Provider value={ctx}>
          <WorldMapContent />
        </WorldMapContext.Provider>
        </TrackedElementsContext.Provider>
      </contentTunnel.In>
    </>
  )
}

export default memo(WorldMap)
