/** @type {boolean} */
export const hasWheelEvent = 'onwheel' in document

/** @type {boolean} */
export const hasMouseWheelEvent = 'onmousewheel' in document

/**
 * X-browser support detection utilities and event handling
 */

/**
 * Firefox detection (for wheel event purposes)
 * @type {boolean}
 */
export const isFirefox = navigator.userAgent.indexOf('Firefox') > -1

/**
 * Whether has `IntersectionObserver`
 * @type {boolean}
 */
export const hasIntersectionObserver = !!window.IntersectionObserver

/**
 * Whether has `ResizeObserver`
 * @type {boolean}
 */
export const hasResizeObserver = !!window.ResizeObserver

/**
 * Check whether supports `createImageBitmap` for loading images
 * @type {boolean}
 */
export const hasCreateImageBitmap = typeof createImageBitmap !== 'undefined'

export const isSafari =
  navigator.userAgent.toLowerCase().indexOf('safari') > -1 &&
  !(navigator.userAgent.toLowerCase().indexOf('chrome') > -1)

const isIOS =
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

export const isAndroid =
  navigator.userAgent.toLowerCase().indexOf('android') > -1
/**
 * Touchscreen detection
 * @returns {boolean} whether is a touch device
 */

const hasCoarsePrimaryPointer =
  (matchMedia('(pointer: coarse)').matches ||
    (!matchMedia('(pointer: fine)').matches && window.onTouchStartInWindow)) &&
  !/Windows.*Firefox/.test(navigator.userAgent)

const onTouchStartInWindow = 'ontouchstart' in window
const touchEventInWindow = 'TouchEvent' in window
const supportsTouchEvents =
  onTouchStartInWindow ||
  (touchEventInWindow && matchMedia('(any-pointer: coarse)').matches)

const hasTouch =
  (window.navigator.maxTouchPoints || 0) > 0 || supportsTouchEvents

const hasAnyHoverOrAnyFinePointer =
  matchMedia('(any-pointer: fine)').matches ||
  matchMedia('(any-hover: hover)').matches

const deviceType =
  hasTouch &&
  (hasAnyHoverOrAnyFinePointer || !hasCoarsePrimaryPointer) &&
  !isAndroid &&
  !isIOS
    ? 'hybrid'
    : hasTouch
      ? 'touchOnly'
      : 'mouseOnly'

export const isTouch = deviceType === 'touchOnly'

export const wheelEvent = isFirefox
  ? 'DOMMouseScroll'
  : hasWheelEvent
    ? 'wheel'
    : 'mousewheel'

/** @type {object} */
export const events = {
  touch: {
    press: 'touchstart',
    release: ['touchend', 'touchcancel'],
    enter: 'touchstart',
    over: 'touchstart',
    move: 'touchmove',
    leave: ['touchend', 'touchcancel'],
    out: ['touchend', 'touchcancel'],
    click: 'touchend',
    wheel: wheelEvent
  },
  mouse: {
    press: 'mousedown',
    release: ['mouseup'],
    enter: 'mouseenter',
    over: 'mouseover',
    move: 'mousemove',
    leave: ['mouseleave'],
    out: ['mouseout'],
    click: 'click',
    wheel: wheelEvent
  }
}[isTouch ? 'touch' : 'mouse']

/**
 * Normalize a mouse/touch event to take into account touches
 * @param {object} e - the input event data
 * @returns {object} the normalized event
 */
export const normalizePointerEvent = e =>
  (e.targetTouches && e.targetTouches[0]) ||
  (e.changedTouches && e.changedTouches[0]) ||
  e

/**
 * Normalize mousewheel event
 * @param {object} e - the event data
 * @returns {object} the normalized event
 */
export const normalizeWheelEvent = e => {
  let spinY = 0
  let deltaY = 0

  if ('detail' in e) spinY = e.detail

  if ('wheelDelta' in e) spinY = -e.wheelDelta / 120

  if ('wheelDeltaY' in e) spinY = -e.wheelDeltaY / 120

  deltaY = spinY * 10

  if ('deltaY' in e) deltaY = e.deltaY

  if (deltaY && e.deltaMode) {
    if (e.deltaMode === 1) {
      deltaY *= 40
    } else {
      deltaY *= 800
    }
  }

  if (deltaY && !spinY) spinY = deltaY < 1 ? -1 : 1

  return {
    spinY,
    deltaY
  }
}

/** @type {object | boolean} */
export const controlledEvent = (() => {
  let passive = false

  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: () => {
          passive = { passive: false }
        }
      })
    )
  } catch (err) {}

  return passive
})()

/** @type {object | boolean} */
export const passiveEvent = (() => {
  let passive = true

  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: () => {
          passive = { passive: true }
        }
      })
    )
  } catch (err) {}

  return passive
})()
