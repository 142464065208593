import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Vector3 } from 'three'
import { getLatLngFromPosition } from '@/lib/util/latlng'
import { MAP_DATA_SRC, MAP_HALF_SIZE, MAP_SIZE } from '../config'
import formatLatLngToDMS from './util/formatLatLngToDMS'
import loadMapData from './util/loadMapData'

/**
 * The `useWorldMap` hook
 * @returns {object} the context object
 */
export default function useWorldMap () {
  const containerRef = useRef()
  const sceneRef = useRef()
  const [mapData, setMapData] = useState(null)
  const mapState = useMemo(() => ({
    isDragging: false,
    size: { value: MAP_SIZE },
    position: { value: new Vector3() },
    zoom: { value: 0.5 },
    latlng: {
      lat: 0,
      lng: 0,
      pretty: {
        lat: '0°0\'0.0"N',
        lng: '0°0\'0.0"E'
      }
    }
  }), [])

  const loadMap = useCallback(async () => {
    const data = await loadMapData(MAP_DATA_SRC, MAP_SIZE)

    setMapData(data)
  }, [])

  const updateLatLng = useCallback(() => {
    // Normalize x and y: 0,0 = top-left and 1,1 = bottom-right of the image
    const normalizedX = (MAP_HALF_SIZE - mapState.position.value.x) / MAP_SIZE
    const normalizedY = (mapState.position.value.y + MAP_HALF_SIZE) / MAP_SIZE
    const { lat, lng } = getLatLngFromPosition(normalizedX, normalizedY)
    const { lat: prettyLat, lng: prettyLng } = formatLatLngToDMS(lat, lng)

    mapState.latlng.lat = Number(lat.toFixed(4))
    mapState.latlng.lng = Number(lng.toFixed(4))
    mapState.latlng.pretty.lat = prettyLat
    mapState.latlng.pretty.lng = prettyLng
  }, [])

  const onFrame = useCallback(() => {
    updateLatLng()
  }, [])

  useEffect(() => {
    loadMap()
  }, [])

  const ctx = useMemo(() => ({
    containerRef,
    sceneRef,
    mapState,
    mapData,
    onFrame
  }), [mapState, mapData, onFrame])

  return ctx
}
