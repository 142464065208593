/**
 * `toDMS`
 * @param {number} deg - the input
 * @returns {object} the parsed DMS
 */
function toDMS (deg) {
  const absolute = Math.abs(deg)
  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(1)

  return { degrees, minutes, seconds }
}

/**
 * Converts decimal latitude and longitude into DMS (degrees, minutes, seconds) format.
 * @param {number} latitude - The latitude in decimal degrees.
 * @param {number} longitude - The longitude in decimal degrees.
 * @returns {string} The formatted DMS string (e.g., "43°49'12.0\"S 169°53'24.0\"E").
 */
export default function formatLatLngToDMS (latitude, longitude) {
  // Helper function to convert decimal degrees to DMS
  const latDMS = toDMS(latitude)
  const latDirection = latitude >= 0 ? 'N' : 'S'

  const lonDMS = toDMS(longitude)
  const lonDirection = longitude >= 0 ? 'E' : 'W'

  return {
    lat: `${latDMS.degrees}°${latDMS.minutes}'${latDMS.seconds}"${latDirection}`,
    lng: `${lonDMS.degrees}°${lonDMS.minutes}'${lonDMS.seconds}"${lonDirection}`
  }
}
