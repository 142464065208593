import {
  controlledEvent,
  events,
  isTouch,
  normalizePointerEvent,
  normalizeWheelEvent
} from '@/lib/util/support'
import BasePointer from './BasePointer'
import { getPinchGestureScaleOffset, getTouchCenter, getTouches } from './util'

/** @type {number} */
const DOUBLE_TAP_WAIT = 200

/**
 * The `OnPointer` class is a utility class that is used to managed
 * pointer based events on the DOM. It extends the `BasePointer` which
 * manages the core aspects that don't rely on DOM based behavior
 * (so that it may be used in environments where the DOM is not available,
 * such as web workers, via the `OnVirtualPointer` alternative)
 */
export default class OnPointer extends BasePointer {
  /** @type {object} */
  config = {
    isFirstMove: false,
    isFirstPinch: false,
    lastTouches: [],
    lastTouchStart: -1,
    enter: { isListening: false },
    leave: { isListening: false },
    press: { isListening: false },
    click: {
      isListening: false,
      doubleTapTimeout: null,
      wasDoubleTap: false,
      wasPinch: false
    },
    move: { isListening: false },
    release: { isListening: false },
    wheel: { isListening: false }
  }

  /** @type {HTMLElement} */
  target = null

  /**
   * @class
   * @param {HTMLElement} target - the target node to bind events to
   * @param {object?} props - the config props
   */
  constructor (target, props) {
    super(props)

    this.target = target
  }

  /**
   * Cancel event
   * @param {object} e - the event data
   * @private
   */
  cancelEvent (e) {
    if (!this.props.preventDefault) {
      // Configured to not prevent default
      return
    }

    e.stopPropagation()
    e.preventDefault()
  }

  /**
   * Set the pointer position
   * @param {object} e - the event data
   * @returns {boolean} whether the position changed (from previous recorded)
   * @override
   * @protected
   */
  setPointerPosition (e) {
    const { clientX: x, clientY: y } = normalizePointerEvent(e)
    const didPositionChange = x !== this.state.x || y !== this.state.y
    const fingers = e.touches ? e.touches.length : 1

    this.setState({ x, y, fingers })

    return didPositionChange
  }

  /**
   * Set the cursor type
   * @param {string} cursorType - the cursor type
   * @public
   * @abstract
   */
  setCursorType (cursorType) {
    this.handleCursorTypeChange(cursorType)
    this.target.style.cursor = cursorType
  }

  /**
   * Sets the wheel data
   * @param {object} e - the event data
   * @override
   * @protected
   */
  setWheel (e) {
    const { deltaY, spinY } = normalizeWheelEvent(e)

    this.setState({ deltaY, spinY })
  }

  /**
   * Checks whether a double tap occurred
   * @param {object} e - the event data
   * @returns {boolean} whether double tap was detected
   * @private
   */
  checkDoubleTap (e) {
    if (!this.callbacks.doubleTap.size) {
      // Double tap isn't in use, skip
      return false
    }

    const now = Date.now()
    let wasDoubleTap = false

    if (this.state.fingers > 1) {
      // Two finger gesture, reset touch start
      this.config.lastTouchStart = null
    }

    if (now - this.config.lastTouchStart < DOUBLE_TAP_WAIT) {
      // Time between last event and now matches double tap
      clearTimeout(this.config.click.doubleTapTimeout)
      wasDoubleTap = true
    }

    if (this.state.fingers === 1) {
      // Was one finger, record last event
      this.config.lastTouchStart = now
    }

    return wasDoubleTap
  }

  /**
   * Sets the pinch gesture informat
   * @param {object} e - the event data
   * @override
   * @protected
   */
  setPinchGesture (e) {
    const currentTouches = getTouches(e.touches)

    this.setState({
      pinchGestureScaleOffset: getPinchGestureScaleOffset(
        this.config.lastTouches,
        currentTouches
      ),
      pinchGestureCenter: getTouchCenter(currentTouches)
    })
  }

  /**
   * Handles the pointer leave event
   * @param {object} e - the event data
   * @override
   * @protected
   */
  handleLeave = e => {
    const node = e.toElement || e.relatedTarget

    if (this.target.contains(node) || node === this.target) {
      // Is still over or inside target, skip leave
      return
    }

    this.setState({ isEntered: false })
    this.handleGenericPositionEvent(e, this.callbacks.leave)
  }

  /**
   * Handles the pointer press event
   * @param {object} e - the event data
   * @override
   * @protected
   */
  handlePress = e => {
    if (!this.state.isEntered) {
      // If not entered, invoke that first
      this.handleEnter(e)
    }

    this.cancelEvent(e)

    if (typeof e.button === 'number' && e.button !== 0) {
      // Is right/other click

      if (this.state.isPressed) {
        // Release if already in pressed state (& right click)
        this.handleRelease(e)
      }

      return
    }

    this.setPointerPosition(e)

    this.setState({
      isPressed: true
    })

    this.config.isFirstMove = true
    this.config.isFirstPinch = true

    if (this.checkDoubleTap(e)) {
      this.config.click.wasDoubleTap = true
      // Was double tap
      this.handleDoubleTap(e)

      return
    }

    this.callbacks.press.forEach(this.executeCallbackPosition)
  }

  /**
   * Handle click
   * @param {object} e - the event data
   * @override
   * @protected
   */
  handleClick = e => {
    if (!this.state.isClickEnabled) {
      // If click is not enabled, skip
      return
    }

    if (this.callbacks.doubleTap.size) {
      clearTimeout(this.config.click.doubleTapTimeout)

      this.config.click.doubleTapTimeout = setTimeout(() => {
        if (this.config.click.wasDoubleTap || this.config.click.wasPinch) {
          this.config.click.wasDoubleTap = false
          this.config.click.wasPinch = false

          return
        }

        this.callbacks.click.forEach(this.executeCallbackPosition)
      }, DOUBLE_TAP_WAIT)

      return
    }

    this.callbacks.click.forEach(this.executeCallbackPosition)
  }

  /**
   * Handles the pointer move event
   * @param {object} e - the event data
   * @override
   * @protected
   */
  handleMove = e => {
    if (this.state.isPressed) {
      this.cancelEvent(e)
    }

    if (this.config.isFirstMove) {
      // Was the first move (after press)
      this.config.isFirstMove = false
      this.config.lastTouches = getTouches(e.touches)
    }

    const didPositionChange = this.setPointerPosition(e)

    if (this.state.fingers === 2) {
      // Is two finger gesture
      this.handlePinchGesture(e)
      this.config.click.wasPinch = true
      this.config.isFirstPinch = false
      this.config.lastTouches = getTouches(e.touches)

      return
    }

    if (!didPositionChange) {
      // If position didn't change, skip
      return
    }

    this.config.lastTouchStart = -1

    this.callbacks.move.forEach(this.executeCallbackPosition)
  }

  /**
   * Handles the pointer wheel event
   * @param {object} e - the event data
   * @override
   * @protected
   */
  handleWheel = e => {
    this.setWheel(e)
    this.cancelEvent(e)
    this.callbacks.wheel.forEach(this.executeCallbackWheel)
  }

  /**
   * Add callback listener. Used to reduce repetition
   * @param {object} props - the config props
   * @param {Function} props.callback - the callback
   * @param {string|null} props.callbackType - the type of callback to register to (optional)
   * @param {string | Array} props.event - the event listener type (string or array for multiple)
   * @param {Function} props.handler - the handler function
   * @param {boolean} props.isEventArray - whether the event is an array (handle multiple)
   * @param {HTMLElement} props.target - the target element to addEventListener to
   * @param {string} props.type - the type
   * @private
   */
  addCallbackListener ({
    callback,
    callbackType = null,
    event,
    handler,
    isEventArray = false,
    target = this.target,
    type
  }) {
    this.callbacks[callbackType || type].add(callback)

    if (this.config[type].isListening) {
      // Already has listener bound, return
      return
    }

    this.config[type].isListening = true

    if (isEventArray) {
      // Is an event array, loop through...
      event.forEach(eventType => {
        target.addEventListener(eventType, handler, controlledEvent)
      })

      return
    }

    target.addEventListener(event, handler, controlledEvent)
  }

  /**
   * Delete callback listener. Used to reduce repetition
   * @param {object} props - the config props
   * @param {Function} props.callback - the callback
   * @param {string|null} props.callbackType - the type of callback to register to (optional)
   * @param {string | Array} props.event - the event listener type (string or array for multiple)
   * @param {Function} props.handler - the handler function
   * @param {boolean} props.isEventArray - whether the event is an array (handle multiple)
   * @param {HTMLElement} props.target - the target element to addEventListener to
   * @param {string} props.type - the type
   * @private
   */
  deleteCallbackListener ({
    callback,
    callbackType,
    event,
    handler,
    isEventArray,
    target = this.target,
    type
  }) {
    const callbackSet = this.callbacks[callbackType || type]

    callbackSet.delete(callback)

    if (callbackSet.size) {
      // Still has callbacks, don't unbind listener
      return
    }

    this.config[type].isListening = false

    if (isEventArray) {
      // Is an event array, loop through...
      event.forEach(eventType => {
        target.removeEventListener(eventType, handler, controlledEvent)
      })

      return
    }

    target.removeEventListener(event, handler)
  }

  /**
   * On enter
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onEnter (callback) {
    this.addCallbackListener({
      callback,
      type: 'enter',
      event: events.enter,
      handler: this.handleEnter
    })
  }

  /**
   * Off enter
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offEnter (callback) {
    this.deleteCallbackListener({
      callback,
      type: 'enter',
      event: events.enter,
      handler: this.handleEnter
    })
  }

  /**
   * On leave
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onLeave (callback) {
    this.addCallbackListener({
      callback,
      type: 'leave',
      event: events.out,
      isEventArray: true,
      handler: this.handleLeave
    })
  }

  /**
   * Off leave
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offLeave (callback) {
    this.deleteCallbackListener({
      callback,
      type: 'leave',
      event: events.out,
      isEventArray: true,
      handler: this.handleLeave
    })
  }

  /**
   * On press
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onPress (callback) {
    this.addCallbackListener({
      callback,
      type: 'press',
      event: events.press,
      handler: this.handlePress
    })
  }

  /**
   * Off press
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offPress (callback) {
    this.callbacks.press.delete(callback)

    if (this.callbacks.doubleTap.size || this.callbacks.press.size) {
      // Check both double tap and press size
      return
    }

    this.config.press.isListening = false
    this.target.removeEventListener(events.press, this.handlePress)
  }

  /**
   * On press
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onClick (callback) {
    this.addCallbackListener({
      callback,
      type: 'click',
      event: events.click,
      handler: this.handleClick
    })
  }

  /**
   * Off click
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offClick (callback) {
    clearTimeout(this.config.click.doubleTapTimeout)

    this.deleteCallbackListener({
      callback,
      type: 'click',
      event: events.click,
      handler: this.handleClick
    })
  }

  /**
   * On double tap
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onDoubleTap (callback) {
    this.addCallbackListener({
      callback,
      callbackType: 'doubleTap',
      type: 'press',
      event: events.press,
      handler: this.handlePress
    })
  }

  /**
   * Off double tap
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offDoubleTap (callback) {
    this.callbacks.doubleTap.delete(callback)

    if (this.callbacks.doubleTap.size || this.callbacks.press.size) {
      // Check both double tap & press size
      return
    }

    this.config.press.isListening = false
    this.target.removeEventListener(events.press, this.handlePress)
  }

  /**
   * On move
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onMove (callback) {
    this.addCallbackListener({
      callback,
      type: 'move',
      event: events.move,
      handler: this.handleMove,
      target: isTouch ? this.target : window
    })
  }

  /**
   * Off move
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offMove (callback) {
    this.callbacks.move.delete(callback)

    if (this.callbacks.pinchGesture.size || this.callbacks.move.size) {
      // Check pinch gesture & move size
      return
    }

    this.config.move.isListening = false

    const target = isTouch ? this.target : window

    target.removeEventListener(events.move, this.handleMove)
  }

  /**
   * On pinch gesture
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onPinchGesture (callback) {
    this.addCallbackListener({
      callback,
      callbackType: 'pinchGesture',
      type: 'move',
      event: events.move,
      handler: this.handleMove,
      target: isTouch ? this.target : window
    })
  }

  /**
   * Off pinch gesture
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offPinchGesture (callback) {
    this.callbacks.pinchGesture.delete(callback)

    if (this.callbacks.pinchGesture.size || this.callbacks.move.size) {
      // Check pinch gesture & move size
      return
    }

    this.config.move.isListening = false

    const target = isTouch ? this.target : window

    target.removeEventListener(events.move, this.handleMove)
  }

  /**
   * On release
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onRelease (callback) {
    this.addCallbackListener({
      callback,
      type: 'release',
      event: events.release,
      isEventArray: true,
      handler: this.handleRelease,
      target: window
    })
  }

  /**
   * Off release
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offRelease (callback) {
    this.deleteCallbackListener({
      callback,
      type: 'release',
      event: events.release,
      isEventArray: true,
      handler: this.handleRelease,
      target: window
    })
  }

  /**
   * On wheel
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  onWheel (callback) {
    this.addCallbackListener({
      callback,
      type: 'wheel',
      event: events.wheel,
      handler: this.handleWheel
    })
  }

  /**
   * Off wheel
   * @param {Function} callback - the callback to register
   * @override
   * @public
   */
  offWheel (callback) {
    this.deleteCallbackListener({
      callback,
      type: 'wheel',
      event: events.wheel,
      handler: this.handleWheel
    })
  }
}
