import { useMemo } from 'react'
import constants from '@/app/constants'

/**
 * The `useImageCanvas`
 * @param {object} props - the component props
 * @returns {string} the src url
 */
export default function useCanvasImage (props) {
  const {
    path: overridePath = constants.PATH_ASSETS,
    sizes = [],
    src: baseSrc = '',
    transform: inputTransform = null
  } = props
  const inputSrc = useMemo(
    () => baseSrc.replace(`${constants.PATH_ASSETS}/`, ''),
    [baseSrc]
  )
  const isResizable = useMemo(
    () => overridePath.includes('imagekit'),
    [overridePath]
  )
  const isRelative = useMemo(() => !inputSrc.includes('http'), [inputSrc])

  const src = useMemo(() => {
    if (!isRelative) {
      return inputSrc
    }

    const outputSrc = inputSrc
    let transform = inputTransform

    if (sizes.length) {
      const screenWidth = screen.width

      sizes.sort((a, b) => b[1] - a[1]) // Sort in descending order of threshold sizes

      let selectedSize = null

      for (const [sizeTransform, threshold] of sizes) {
        if (screenWidth < threshold) {
          selectedSize = sizeTransform

          break
        }
      }

      if (selectedSize) {
        transform = selectedSize.transform
      }
    }

    let standard = `${overridePath || constants.PATH_ASSETS}/${outputSrc}`

    if (isResizable && transform) {
      const { height, width } = transform
      const transforms = []

      if (width) {
        transforms.push(`w-${width}`)
      }

      if (height) {
        transforms.push(`h-${height}`)
      }

      const transformed = `tr:${transforms.join(',')},q-80`

      standard = `${
        overridePath || constants.PATH_ASSETS
      }/${transformed}/${outputSrc}`
    }

    return standard
  }, [inputSrc, inputTransform, isRelative, overridePath, sizes])

  return src
}
