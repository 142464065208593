/**
 * Loads the map data
 * @param {string} url - the url
 * @param {number} size - the size to scale to fit
 * @returns {object} the parsed result
 */
export default async function loadMapData (url, size) {
  const response = await fetch(url)
  const buffer = await response.arrayBuffer()
  const data = new Uint8Array(buffer)

  // Read width and height from the binary file
  const width = data[0] | (data[1] << 8) // Uint16 for width
  const height = data[2] | (data[3] << 8) // Uint16 for height

  const positions = []
  const uvs = []
  let index = 4 // Start after width and height

  // Iterate through the binary data
  while (index < data.length) {
    const runStart = data[index++] | (data[index++] << 8) // Uint16 for x start
    const runLength = data[index++] | (data[index++] << 8) // Uint16 for run length
    const y = height - (data[index++] | (data[index++] << 8)) // Uint16 for y

    for (let i = 0; i < runLength; i++) {
      const x = runStart + i

      // Normalize x and y to range 0-1
      const normalizedX = x / width
      const normalizedY = y / height

      // Positions (centered in 3D space)
      positions.push(
        normalizedX * width - width / 2,
        normalizedY * height - height / 2,
        0 // Default elevation (z-value)
      )

      // UV coordinates
      uvs.push(normalizedX, normalizedY)
    }
  }

  return {
    width,
    height,
    positions: new Float32Array(positions),
    uvs: new Float32Array(uvs)
  }
}
