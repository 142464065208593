/**
 * Linear interpolation
 * @param {number} v0 - start
 * @param {number} v1 - end
 * @param {number} t - alpha
 * @returns {number} result
 */
export const lerp = (v0, v1, t) => v0 * (1 - t) + v1 * t

export const lerpClamp = (start, end, number) => {
  let value = (number - start) / (end - start)

  value = Math.max(0, Math.min(1, value))

  return value
}

export const lerpUnclamped = (start, end, number) => {
  const value = (number - start) / (end - start)

  return value
}
