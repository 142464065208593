import '@/styles/index.scss'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ROOT } from '@/app/thread'
import PointerProvider from '@/lib/react/PointerProvider/PointerProvider'
import App from './App'

const root = createRoot(ROOT)

root.render(
  <BrowserRouter>
    <PointerProvider>
      <App />
    </PointerProvider>
  </BrowserRouter>
)
