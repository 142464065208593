import { useThree } from '@react-three/fiber'
import { useCallback, useMemo, useRef } from 'react'
import {
  Color,
  LinearFilter,
  RGBAFormat,
  Scene,
  ShaderMaterial,
  Vector2,
  WebGLRenderTarget
} from 'three'
import fragmentShader from './shaders/pick.frag'

const fbo = new WebGLRenderTarget(1, 1, {
  minFilter: LinearFilter,
  magFilter: LinearFilter,
  format: RGBAFormat
})
const pixelBuffer = new Uint8Array(4 * fbo.width * fbo.height)
const clearColor = new Color(0xffffff)

/**
 * The `useGPUPicker`
 * @returns {object} the context object
 */
export default function useGPUPicker () {
  const { camera, gl, scene, size } = useThree()
  const sizeRef = useRef(size)
  const renderList = useRef([])
  const uv = useRef(new Vector2())
  const objectRef = useRef(null)
  const materialCache = useRef(new Map())

  const getPickMaterial = useCallback(object => {
    const id = object.id

    if (materialCache.current.has(id)) {
      return materialCache.current.get(id)
    }

    // Create a new pick material using the original vertex shader and uniforms
    const originalMaterial = object.material
    const pickMaterial = new ShaderMaterial({
      vertexShader: originalMaterial.vertexShader,
      fragmentShader, // Replace the fragment shader
      uniforms: { ...originalMaterial.uniforms } // Clone the original uniforms
    })

    materialCache.current.set(id, pickMaterial) // Cache the new material

    return pickMaterial
  }, [])

  const [pickScene, baseColor] = useMemo(() => {
    const pickScene = new Scene()

    const getId = object => object.id

    const onAfterRender = () => {
      if (!objectRef.current) {
        return
      }

      const sceneRenderList = gl.renderLists.get(scene, 0)

      renderList.current = [
        ...sceneRenderList.opaque.map(getId),
        ...sceneRenderList.transmissive.map(getId),
        ...sceneRenderList.transparent.map(getId)
      ]

      const object = objectRef.current
      const id = object.id

      if (!~renderList.current.indexOf(id)) {
        return
      }

      const renderMaterial = getPickMaterial(object)

      gl.renderBufferDirect(
        camera,
        scene,
        object.geometry,
        renderMaterial,
        object,
        null
      )
    }

    pickScene.onAfterRender = onAfterRender

    const baseColor = gl.getClearColor(new Color())

    return [pickScene, baseColor]
  }, [])

  useMemo(() => {
    sizeRef.current = {
      ...size
    }
  }, [size])

  const pick = useCallback(({ x, y }, object) => {
    objectRef.current = object

    camera.setViewOffset(
      sizeRef.current.width,
      sizeRef.current.height,
      x,
      y,
      1,
      1
    )

    const currentRenderTarget = gl.getRenderTarget()
    const isVisible = object.material.visible

    if (!isVisible) {
      object.material.visible = true
    }

    gl.setRenderTarget(fbo)
    gl.setClearColor(clearColor)
    gl.clear()
    gl.render(scene, camera)
    gl.render(pickScene, camera)

    gl.readRenderTargetPixels(fbo, 0, 0, fbo.width, fbo.height, pixelBuffer)

    gl.setRenderTarget(currentRenderTarget)
    gl.setClearColor(baseColor)
    camera.clearViewOffset()

    if (!isVisible) {
      object.material.visible = false
    }

    if (pixelBuffer.every(val => val === 255)) return null

    uv.current.set(
      pixelBuffer[0] / 255.0,
      pixelBuffer[1] / 255.0
    )

    return uv.current
  }, [])

  const ctx = useMemo(
    () => ({
      pick
    }),
    []
  )

  return ctx
}
