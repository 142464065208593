import OnPointer from '@/lib/OnPointer'
import { SVGLoader } from '@/lib/three/SVGLoader'

/** @type {HTMLElement} */
export const ROOT = document.getElementById('root')

export const onPointer = new OnPointer(document.body)

export const svgLoader = new SVGLoader()

// Whether is debug
export const isDebug = location.search.includes('edebug')

// Whether is staging
export const isStaging =
  location.origin.indexOf('staging') > -1 ||
  location.origin.indexOf('ngrok') > -1 ||
  location.origin.indexOf('localhost') > -1
