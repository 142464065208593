import { shaderMaterial } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import { Color, Vector2, Vector3 } from 'three'
import fragmentShader from './minimap.frag'
import vertexShader from './minimap.vert'

const MinimapMaterial = shaderMaterial(
  {
    map: null,
    mapSize: 1024,
    size: new Vector2(),
    position: new Vector3(),
    zoom: 5,
    color: new Color()
  },
  vertexShader,
  fragmentShader
)

extend({ MinimapMaterial })

export { MinimapMaterial }
