import { useTexture } from '@react-three/drei'
import { memo, useEffect, useRef } from 'react'
import { Vector3 } from 'three'
import { useWorldMapContext } from '@/components/WorldMap/WorldMap'
import useCanvasImage from '@/lib/react/Img/useCanvasImage'
import { MinimapMaterial } from './MinimapMaterial/MinimapMaterial'
import { useTrackedElement } from '@/providers/TrackedElements/hooks/useTrackedElement'

/**
 * The `Minimap`
 * @returns {React.ReactElement} the element
 */
function Minimap () {
  const mapSrc = useCanvasImage({
    src: 'img/map/hillshade2.jpg'
  })
  const materialRef = useRef()
  const textureMap = useTexture(mapSrc)
  const { mapState } = useWorldMapContext()
  const minimapBox = useTrackedElement('minimap')
  const minimapRef = useRef()

  useEffect(() => {
    materialRef.current.uniforms.mapSize = mapState.size
    materialRef.current.uniforms.zoom = mapState.zoom
    materialRef.current.uniforms.position = mapState.position
  }, [])

  return (
    <mesh
      ref={minimapRef}
      position={minimapBox.position}
      scale={[minimapBox.width, minimapBox.height, 1]} // Scale in screen space
      renderOrder={1} // Render above main scene
    >
      <planeGeometry args={[1, 1, 30, 30]} />
      <minimapMaterial
        ref={materialRef}
        key={MinimapMaterial.key}
        mapSize={mapState.size.value}
        size={minimapBox.size}
        map={textureMap}
        zoom={1}
        color={'#eaa94d'}
        depthWrite={false}
        transparent={true}
        wireframe={false}
      />
    </mesh>
  )
}

export default memo(Minimap)
